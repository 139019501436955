<template>
  <div class="control-tab">
    <div class="tab-item"  v-for="(item,index) in titlelist" :key="index" :class="curIndex===index?'active':''" @click="controlTabClick(item,index)">
      {{item.fieldName}}
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "",
  data() {
    return {
      curIndex: 0
    }
  },
  props: {
    titlelist: {
      type: Array,
      default(){
        return []
      }
    },
    awardType: {
      type: Number
    }
  },
  watch: {
    awardType(newVal, oldVal) {
      if(newVal!=0){
        this.curIndex = 0
      }
    }
  },
  components: {

  },
  methods: {
    controlTabClick(item,index){
      this.curIndex = index
      this.$emit("controlTabClick",item.fieldValue)
    }
  },
}
</script>

<style lang='less' scoped>
.control-tab {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 36px;
  color:#59493f;
  background: url('../../../assets/img/control-tab-bg.png');
  background-size: 100% 100%;
  height: 100px;
  width: 100%;

  .tab-item {
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    position: relative;

    &:first-child {
      border-right: 1px solid #a8864b;
    }
    &:last-child {
      border-left: 1px solid #a8864b;
    }
    
    &.active {
      font-weight: 600;
      background: url('../../../assets/img/control-tab-active-bg.png');
      background-size: 100% 100%;

      // &::after {
      //   content: "";
      //   display: inline-block;
      //   width: 180px;
      //   height: 8px;
      //   background: url('../../../assets/img/control-tab-item-s.png');
      //   background-size: 100% 100%;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   right: 0;
      // }
    }
  }
}

</style>
