import {request} from './request'

// 获取轮播图列表 /api/ApiWebSite/GetCarauselList
export function getCarauselList(){
  return request({
    url: '/api/ApiWebSite/GetCarauselList',
    method: 'get',
    params: {
      webId: '1',
      groupName: '1',
    }
  })
}

// 新闻列表 /api/ApiWebSite/GetNewsInfoList
export function getNewsInfoList(){
  return request({
    url: '/api/ApiWebSite/GetNewsInfoList',
    method: 'get',
    params: {
      typeId: '1',
      page: '1',
      size: '10',
    }
  })
}

// 获取单个新闻内容 /api/ApiWebSite/GetNewsInfo
export function getNewsInfo(id){
  return request({
    url: '/api/ApiWebSite/GetNewsInfo',
    method: 'get',
    params: {
      id
    }
  })
}
