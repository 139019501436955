<template>
  <van-swipe class="my-swipe" :autoplay="autoplay" indicator-color="white" @change="onChange">
    <van-swipe-item v-for="item in homeImgList" :key='item.id' @click="goToOther(item)">
      <van-image fit="cover" :src="item.imgUrl" />
    </van-swipe-item>
  </van-swipe>
</template>

<script type="text/javascript">
import { Swipe, SwipeItem } from 'vant'
import { Image as VanImage } from 'vant'

export default {
  name: "HomeSwiper",
  data() {
    return {
      current: 0,
      autoplay: 3000
    }
  },
  props: {
    homeImgList: {
      type: Array,
      default(){
        return []
      }
    }
  },
  components: {
    [Swipe.name]: Swipe,
		[SwipeItem.name]: SwipeItem,
		[VanImage.name]: VanImage,
  },
  mounted() {
    
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    goToOther(item) {
      if(item.linkUrl){
        if(item.linkUrl.indexOf('http')>0){
          window.location.href = item.linkUrl
        }else{
          window.location.href = 'https://'+item.linkUrl
        }
        
      }
    }
  }
}
</script>

<style scoped>
.my-swipe {
  
}
.my-swipe .van-swipe-item .van-image {
  width: 100%;
  height: 300px;
  vertical-align: middle;
}
.my-swipe .van-swipe-item .van-image img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 3px 8px;
  font-size: 13px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  color: #fff;
}
</style>
