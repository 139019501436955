<template>
	<div class="home">
    <div class="top">
      <div class="top-img">
        <home-swiper :homeImgList='homeImgList' class="details-swiper"></home-swiper>
      </div>
    </div>
    <control-tab :titlelist='awardLevelList' @controlTabClick='controlTabClick' :awardType='awardType'></control-tab>
    <div class="list">
      <div v-for="item in awardList" :key="item.awardYear">
        <div class="item" v-if="item.products.length>0">
          <div class="item-title">
            <img src="../../assets/img/title-left.png" alt="">{{item.awardYear}}年
          </div>
          <div class="item-box">
            <div class="item-box-item" v-for="productItem in item.products" :key="productItem.id" @click="goToDetail(productItem)">
              <div class="product-img"><img :src="productItem.firstPic" alt=""></div>
              <div class="product-title">{{productItem.productName}}</div>
              <div class="product-subtitle">{{productItem.userName}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ControlTab from '@/components/content/controltab/ControlTab'
import HomeSwiper from '@/components/common/swiper/HomeSwiper'

import {listDict} from '../../network/system'

import {listProductWeb} from '../../network/product'

import {getCarauselList} from '../../network/apiWebSite'

export default {
  name: "Home",
  data () {
    return {
      awardLevelList: [],
      awardLevel: 0,
      defaultAwardLevel: 0,
      awardList: [],
      homeImgList: []
    };
  },
  props: {
    awardType: {
      type: Number
    }
  },
  components: {
    ControlTab,
    HomeSwiper
  },
  mounted() {
    this.listDict('AwardLevel')
    this.getCarauselList()
  },
  watch: {
    awardLevel(newVal, oldVal) {
      if(newVal!=0&&this.awardType!=0){
        this.listProductWeb(this.awardType,this.awardLevel)
      }
    },
    awardType(newVal, oldVal) {
      if(newVal!=0&&this.awardLevel!=0){
        this.awardLevel = this.defaultAwardLevel
        this.listProductWeb(this.awardType,this.awardLevel)
      }
    }
  },
  methods: {
    controlTabClick(value) {
      this.awardLevel = value
    },
    goToDetail(item) {
      this.$router.push({name:'detail',query:{id:item.id}})
    },
    // 网络请求
    listDict(field) {
      listDict(field).then(res=>{
        if(res.status=200&&res.data){
          if(field==='AwardLevel'){
            this.awardLevelList = res.data
            this.awardLevel = res.data[0].fieldValue
            this.defaultAwardLevel = res.data[0].fieldValue
          }
        }
      })
    },
    listProductWeb(awardType,awardLevel){
      listProductWeb(awardType,awardLevel).then(res=>{
        if(res.status===200&&res.data){
          this.awardList = res.data
        }
      })
    },
    getCarauselList(){
      getCarauselList().then(res=>{
        if(res.status===200&&res.data){
          this.homeImgList = res.data
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.home {

  .top {
    width: 100%;
    height: 652px;
    background: url('../../assets/img/top-bg.png');
    background-size: 100% 100%;
    position: relative;

    .top-img {
      width: 710px;
      height: 300px;
      border: 5px solid #f2d19d;
      border-radius: 20px;
      background-color: #eee;
      position: absolute;
      top: 328px;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
    }
  }

  .list {
    min-height: calc(100vh - 652px - 98px - 36px);
    background-color: #362e2b;
    padding: 0 19px 19px 19px;
    margin-bottom: 98px;

    .item {

      .item-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 29px 0 29px 2px;
        font-size: 30px;
        color: #fff;

        img {
          width: 6px;
          height: 30px;
          margin-right: 23px;
        }
      }

      .item-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .item-box-item {
          width: 344px;
          height: 460px;
          border: 2px solid #ccab73;
          border-radius: 10px;
          overflow: hidden;
          margin-bottom: 20px;

          .product-img {
            width: 100%;
            height: 328px;
            background-color: #eee;
            >img {
              width: 100%;
              height: 100%;
            }
          }
          .product-title {
            font-size: 30px;
            color: #fff;
            padding: 20px 18px 0 18px;
          }
          .product-subtitle {
            font-size: 24px;
            color: #ffedce;
            padding: 15px 18px 0 18px;
          }
        }
      }
    }
  }
}
</style>
