import {request} from './request'

// 产品展示 /api/Product/ListProductWeb
export function listProductWeb(awardType,awardLevel){
  return request({
    url: '/api/Product/ListProductWeb',
    method: 'get',
    params: {
      awardType,
      awardLevel
    }
  })
}

// 产品详情 /api/Product/ListProductInfo
export function listProductInfo(id){
  return request({
    url: '/api/Product/ListProductInfo',
    method: 'get',
    params: {
      id
    }
  })
}
